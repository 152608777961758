const customCkeditorChangeCallback = function (editor) {
    return function() {
        const tables = editor.document.getElementsByTag('table');

        for (let i = 0; i < tables.count(); i++) {
            const table = tables.getItem(i);

            let div = table.getAscendant('div', true);
            if (div && div.hasClass('section-table-container')) {
                console.log('Table is wrapped in a div with class "section-table-container"');
                continue;
            }

            const table1 = table.clone(true);
            table1.insertBefore(table);

            let newDiv = new CKEDITOR.dom.element('div', editor.document);
            newDiv.insertAfter(table1);
            newDiv.addClass('section-table-container');
            table.appendTo(newDiv);
            table1.remove();

            console.log('Table is now wrapped in a div with class "section-table-container"');
        }
    }
}

const handleCkeditorInstance = function (editor) {
    editor.on('change', customCkeditorChangeCallback(editor));
}


const updateEditors = function () {
    let instancesCount = Object.keys(CKEDITOR.instances).length;

    for (let instance = 0; instance < instancesCount; instance++) {
        handleCkeditorInstance(Object.values(CKEDITOR.instances)[instance])
    }
}

$(function () {
    updateEditors();
})

window.customCkeditorChangeCallback = customCkeditorChangeCallback;
window.handleCkeditorInstance = handleCkeditorInstance;
