window.fmSetLink = ($url, componentHash, dataLocale) => {
    let suffix = '-' + componentHash

    if (dataLocale) {
        suffix += '-' + dataLocale
    }

    const imageWrap = document.querySelector('.image-wrap' + suffix);

    Alpine.$data(imageWrap).image = $url;
}
